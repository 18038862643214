import * as R from 'ramda';
import qs from 'qs';
import { createRouter as _createRouter, createWebHistory } from 'vue-router';
import { removeKeycloackHash } from '@/utils';
import { saveRouteToHistory } from './helpers';

import omsModuleRoutes from '@/modules/oms/router';
import productConfigurationModuleRoutes from '@/modules/productConfiguration/router';
// import pricingModuleRoutes from '@/modules/pricing/router';
import productModuleRoutes from '@/modules/product/router';
import billingModuleRoutes from '@/modules/billing/router';
import invoicingModuleRoutes from '@/modules/invoicing/router';
import crmModuleRoutes from '@/modules/crm/router';
import equipmentRecordsModuleRoutes from '@/modules/equipmentRecords/router';
import workflowModuleRoutes from '@/modules/workflow/router';
import amsModuleRoutes from '@/modules/ams/router';
import apxModuleRoutes from '@/modules/reporting/router';
import projectsModuleRoutes from '@/modules/projects/router';
import rolesModuleRoutes from '@/modules/roles/router';
import ticketsModuleRoutes from '@/modules/tickets/router';
import emailLogModuleRoutes from '@/modules/emailLog/router';
import customizationCenterModuleRoutes from '@/modules/customizationCenter/router';
import extensibilityCenterModuleRoutes from '@/modules/extensibilityCenter/router';

export default function createRouter(ability) {
  const router = _createRouter({
    history: createWebHistory(process.env.BASE_URL),

    // Set custom query resolver
    // @see {@link https://www.algolia.com/doc/guides/building-search-ui/going-further/routing-urls/vue/}
    parseQuery(query) {
      return qs.parse(query);
    },
    stringifyQuery(query) {
      const result = qs.stringify(query);

      return result || '';
    },

    routes: [
      // Temp router Will be deleted in main App
      {
        path: '/',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "PageDashboard" */ '@/components/PageDashboard'),
        meta: {
          title: 'Dashboard',
        },
        beforeEnter: (to, from, next) => {
          if (!from.name) {
            router.push({ name: 'order' });
            return;
          }
          next();
        },
      },
      // CRM module
      ...crmModuleRoutes,

      ...ticketsModuleRoutes,

      // Pricing module
      // ...pricingModuleRoutes,

      // Product catalog module
      ...productModuleRoutes,

      // Product config module
      ...productConfigurationModuleRoutes,

      ...omsModuleRoutes,

      // Billing module
      ...billingModuleRoutes,

      ...workflowModuleRoutes,

      ...emailLogModuleRoutes,

      ...customizationCenterModuleRoutes,

      ...rolesModuleRoutes,

      ...extensibilityCenterModuleRoutes,

      ...amsModuleRoutes,

      // Equipment records module
      ...equipmentRecordsModuleRoutes,

      // Projects module
      ...projectsModuleRoutes,

      // Invoicing
      ...invoicingModuleRoutes,

      // Reports
      ...apxModuleRoutes,

      // Errors
      {
        path: '/403',
        name: 'access-denied',
        component: () => import(/* webpackChunkName: "PageAccessDenied" */ '@/components/PageAccessDenied'),
        meta: {
          parent: 'dashboard',
          title: 'Access denied',
        },
      },

      {
        path: '/:pathMatch(.*)*',
        redirect: { name: 'dashboard' },
        meta: {
          parent: 'dashboard',
          title: 'Not Found',
        },
      },
    ],
  });

  router.beforeEach((to, from, next) => {
    const accessSubject = R.pathOr(undefined, ['meta', 'permission'], to);
    const parent = R.pathOr(undefined, ['meta', 'parent'], to);
    const isAddToHistory = R.pathOr(undefined, ['meta', 'isAddToHistory'], to);

    if (accessSubject && !ability.can('manage', accessSubject) && to.name !== 'access-denied') {
      return next('/403');
    }

    if (isAddToHistory && parent) {
      saveRouteToHistory(to.fullPath, parent);
    }

    return next();
  });

  router.afterEach(() => {
    // this code for clear Keycloack state from hash
    // should check if Keycloack have solution
    window.location.hash = removeKeycloackHash(window.location.hash);
  });

  return router;
}
